<template>
  <section id="about" class="overflow-hidden">
    <v-row align="center" justify="center" class="white" no-gutters>
      <v-col class="hidden-sm-and-down" md="6">
        <v-img :src="require('@/assets/aboutEPOC.jpg')" height="100vh" />
      </v-col>
      <v-col class="pa-5" cols="12" md="6">
        <v-row align="center" justify="center">
          <v-col cols="10">
            <base-heading class="info--text">
              Algunos datos sobre la EPOC
            </base-heading>

            <base-text class="mb-5">
              La EPOC es una enfermedad con impacto grave en la calidad de vida:
              la falta de aire, limita las actividades de la vida diaria del
              paciente y su familia.
            </base-text>

            <!--<base-subheading class="info--text"> EPOC </base-subheading>-->

            <base-text class="mb-5">
              En España en el año 2019 se registraron 418703 fallecimientos,
              13937 por EPOC (Fuentes: INE/OCDE).
            </base-text>
            <base-text>
              El coste total de la EPOC en España &gt;1.000.000.000€/año.
            </base-text>
            <base-text>
              El coste medio anual por paciente con EPOC es aproximadamente
              3.077 €, de los cuales 43,8% se corresponden a costes directos
              sanitarios (asistencia, hospitalización y tratamiento), 38,3% con
              costes directos no sanitarios y el 17,9% con pérdidas de
              productividad laboral. El principal determinante del coste directo
              no sanitario fueron los cuidados informales (81,9%)
            </base-text>

            <v-alert outlined color="info">
              <v-row
                v-for="(skill, i) in skills"
                :key="i"
                style="color: #69a1bb"
              >
                <v-col class="text-uppercase" cols="6" v-text="skill.name" />

                <v-col class="text-right" cols="6" v-text="`${skill.value}%`" />

                <v-progress-linear
                  :value="skill.value"
                  color="info"
                  height="8"
                />
              </v-row>
            </v-alert>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import BaseHeading from '@/components/base/Heading'
import BaseText from '@/components/base/Text'

export default {
  components: {
    BaseText,
    BaseHeading
  },
  data: () => ({
    skills: [
      {
        name: 'Infradiagnostico',
        value: 74.7
      },
      {
        name: 'Incidencia en mayores de 40 años',
        value: 11.8
      },
      {
        name: 'Pacientes insatisfechos con su tratamiento',
        value: 30
      },
      {
        name: 'Muertes por COVID-EPOC',
        value: 22
      }
    ]
  })
}
</script>
